import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Tu Vitrina 24/7          
        </h1>
        <h4>Coming soon...</h4>

        
      </header>
    </div>
  );
}

export default App;
